html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &::-webkit-scrollbar {
    width: .2em;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba($primary, .5);
    -webkit-border-radius: 20px;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $primary;
    outline: 1px solid darken($primary, .5);
    -webkit-border-radius: 20px;
    border-radius: 20px;
  }
}

body {
  margin: 0;
  font-family: $font-family-base !important;
  font-size: $font-size-base !important;
  font-weight: $font-weight-base !important;
  background-color: $background-color !important;
  overflow-x: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.row > * {
  position: relative;
}

@for $i from 1 through 6 {
  h#{$i} {
    font-family: 'Georgia Pro';
    font-size: map-get(map-get($heading-sizes, $i), font-size);
    line-height: map-get(map-get($heading-sizes, $i), line-height);
  }
}

p {
  font-size: $font-size-base * 1.2;
  line-height: 24px;
}

i { font-size: $font-size-base }
b { font-weight: 500 }

a {
  color: $primary !important;
  text-decoration: none !important;
  outline: 0;
}

.hover-effect {
  transition: .3s ease-in-out;
  &:hover {
    transform: translateY(-1rem);
  }
}

.highlighted-word {
  color: $warning;
  position: relative;
  display: inline-block;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 1.5px;
    background-color: $warning;
    box-shadow:
      1px 1px 0px 0.5px $warning,
      -1px 1px 0px 0.5px $warning,
      1px -1px 0px 0.5px $warning,
      2px 1px 0px 0.5px $warning,
      -2px -1px 0px 0.5px $warning,
      2px 0px 0px 0.5px $warning,
      -1px -1px 0px 0.5px $warning; // Efeito de spray
    transform: scaleX(1.2) translateY(2px) rotate(-1deg);
  }
}

@media screen and (max-width: 1024px) {
  ::-webkit-scrollbar {
    display: none !important;
  }
}

@media screen and (min-width: 1200px) {
  .container {
    max-width: 1140px !important;
  }
}
